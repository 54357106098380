<template>
  <div>
    <FullCalendar ref="fullCalendar" :options="calendarOptions" />
  </div>
</template>

<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";

export default {

  props: [
    "moniteurEdits",
  ],

  components: {
    FullCalendar,
  },

  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: "timeGridWeek",
        nowIndicator: true,
        eventResizableFromStart: true,
        firstDay: 1,
        allDaySlot: false,
        slotMinTime: "07:00:00",
        slotMaxTime: "21:00:00",
        height: "auto",
        locale: "fr",
        selectable: true,
        timeZone: "local",
        eventClick: this.deleteEvent,
        headerToolbar: {
          start: "prev,next",
          center: "title",
          end: "timeGridWeek,timeGridDay",
        },

        customButtons: {
          prev: {
            // this overrides the prev button
            text: "PREV",
            click: () => {
              console.log("eventPrev");
              let calendarApi = this.$refs.fullCalendar.getApi();
              if(this.calendarOptions.events != null){
                this.calendarOptions.events.forEach((e) => {
                  let start = moment(e.start).subtract(1, "week");
                  let end = moment(e.end).subtract(1, "week");
                  e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                  e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                });
              }

              calendarApi.prev();
            },
          },
          next: {
            // this overrides the next button
            text: "PREV",
            click: () => {
              console.log("eventNext");
              let calendarApi = this.$refs.fullCalendar.getApi();
              if(this.calendarOptions.events != null){
                this.calendarOptions.events.forEach((e) => {
                  let start = moment(e.start).add(1, "week");
                  let end = moment(e.end).add(1, "week");
                  e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                  e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                });
              }

              calendarApi.next();
            },
          },
        },

        events: [],
        select: this.select,
        editable: true,
        selectAllow: this.selectAllow,
        eventChange: this.putDispoEleve,
      },
    };
  },

  mounted() {
    this.calendarOptions.events = [];
    if(this.$store.state.rdvBoite != null){
      this.calendarOptions.events = this.$store.state.rdvBoite;
    }

    if(this.moniteurEdits == null){
      this.calendarOptions.events = [];
    }else{

      if(this.moniteurEdits.rdvs != null){
          this.moniteurEdits.rdvs.forEach(r => {
          let today = new Date();
          let todayDay = today.getDay();
          let startDate = new Date(r.start);
          let endDate = new Date(r.end);
          let startDay = startDate.getDay();
          let endDay = endDate.getDay();
          let diffStart = today.getDate() - todayDay + (todayDay == 0 ? -6 : startDay); // permet de récupérer un jour précis de la semaine actuel en mettant l'id du jour (0 = dimanche 1 = lundi...)
          let diffEnd = today.getDate() - todayDay + (todayDay == 0 ? -6 : endDay); // permet de récupérer un jour précis de la semaine actuel en mettant l'id du jour (0 = dimanche 1 = lundi...)

          startDate = new Date(today.setDate(diffStart));
          endDate = new Date(today.setDate(diffEnd));

          if(startDay == 0){
            startDate = startDate.setDate(startDate.getDate() + 1 * 7);
            startDate = new Date(startDate);
          }

          if(endDay == 0){
            endDate = endDate.setDate(endDate.getDate() + 1 * 7);
            endDate = new Date(endDate);
          }

          let ddStart = String(startDate.getDate()).padStart(2, '0');
          let ddEnd = String(endDate.getDate()).padStart(2, '0');
          let mm = String(today.getMonth() + 1).padStart(2, '0');
          let yyyy = today.getFullYear();

          let start = yyyy + "-" + mm + "-" + ddStart + "T" + r.start.split("T")[1];
          let end = yyyy + "-" + mm + "-" + ddEnd + "T" + r.end.split("T")[1];

          if(r.title == "BA"){
            this.calendarOptions.events.push({
              id: r.id,
              title: "BA",
              start: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
              recurring: "true",
              color: "#3EBB7A",
              type: "boite",
            });
          }else if(r.title == "BM"){
            this.calendarOptions.events.push({
              id: r.id,
              title: "BM",
              start: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
              recurring: "true",
              color: "orange",
              type: "boite",
            });
          }else{
            console.log(start)
            this.calendarOptions.events.push({
              id: r.id,
              title: "INDISPO",
              start: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
              recurring: "true",
              color: "#494949FF",
              type: "boite",
            });
          }
        })
      }


    }
    
  },

  methods: {

    deleteEvent(e){
      let events = [];
      this.calendarOptions.events.forEach(i => {
        if(i.id != e["event"]["_def"]["publicId"]){
          events.push(i);
        }
      })
      this.calendarOptions.events = events;
      this.$store.commit("setRdvBoite", this.calendarOptions.events);
    },

    select(e) {

      if (
        this.$store.state.choiceBoitePlanning == null ||
        this.$store.state.choiceBoitePlanning == ""
      ) {
        alert(
          "Veuillez saisir un type de boite pour pouvoir poser les jours de conduite"
        );
      } else {
        let count = this.calendarOptions.events.length;
        count = count + 1;
        if (this.$store.state.choiceBoitePlanning == "BA") {
          this.calendarOptions.events.push({
            id: count,
            title: "BA",
            start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            recurring: "true",
            color: "#3EBB7A",
            type: "boite",
          });
        } else if(this.$store.state.choiceBoitePlanning == "BM") {
          this.calendarOptions.events.push({
            id: count,
            title: "BM",
            start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            recurring: "true",
            color: "orange",
            type: "boite",
          });
        }else{
          this.calendarOptions.events.push({
            id: count,
            title: "INDISPO",
            start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            recurring: "true",
            color: "#494949FF",
            type: "boite",
          });
        }

        this.$store.commit("setRdvBoite", this.calendarOptions.events);
      }
      
    },
  },
};
</script>
